import Container from '@/components/Container';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { navigate } from 'gatsby';
import React, { FC, useLayoutEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

const PanelContainer = styled.div`
  overflow-x: auto;
  .wrap {
    border-bottom: 3px solid #c6c6c6;
    margin: 0;
    height: 80px;
  }

  .UiTabPanel {
    min-width: 768px;
  }
  ${breakpoint(`tablet`)} {
    .wrap {
      height: 52px;
      border-bottom: 0;
    }
  }
`;

const TabActive = css`
  color: #1cabe2;
  border-bottom: 3px solid #1cabe2;
`;
const TabButton = styled.li<{ active: boolean }>`
  cursor: pointer;
  position: relative;
  padding: 0;
  width: 25% !important;
  text-align: center;
  display: block;
  font-weight: 700;
  line-height: 80px;
  height: 80px;
  font-size: 20px;

  color: #c6c6c6;
  border-bottom: 3px solid #c6c6c6;
  ${(props) => props.active && TabActive}
  white-space: nowrap;
  transition: color 0.2s;

  &:hover {
    ${TabActive}
  }

  ${breakpoint(`tablet`)} {
    font-size: 16px;
    line-height: 52px;
    height: 52px;
  }
`;

const tabs = [
  {
    title: `아동친화도시`,
    link: `/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-cities`,
  },
  {
    title: `아동친화학교`,
    link: `/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-schools`,
  },
  {
    title: `아동친화기업`,
    link: `/what-we-do/advocacy-for-children/public-private-partnership/childrens-rights-and-business`,
  },
  {
    title: `모유수유 캠페인`,
    link: `/what-we-do/advocacy-for-children/public-private-partnership/breastfeeding`,
  },
];

export const PublicPrivateTab: FC<{ currentPath: string }> = ({
  currentPath,
}) => {
  const path = useMemo(() => currentPath.replace(/\/$/, ``), [currentPath]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLLIElement | null>(null);

  // 현재 선택된 탭 버튼으로 스크롤
  useLayoutEffect(() => {
    if (containerRef.current && targetRef.current) {
      containerRef.current.scrollLeft = targetRef.current.offsetLeft;
    }
  }, []);

  return (
    <PanelContainer ref={containerRef}>
      <div className="wrap">
        <Container>
          <ul className="UiTabPanel flex">
            {tabs.map(({ title, link }) => (
              <TabButton
                ref={(ref) => {
                  if (path === link) {
                    targetRef.current = ref;
                  }
                }}
                active={path === link}
                key={link}
                onClick={() => navigate(link)}
              >
                {title}
              </TabButton>
            ))}
          </ul>
        </Container>
      </div>
    </PanelContainer>
  );
};
