import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Bn from '@/assets/img/advocacy-for-children/city_bn@2x.png';
import CertifyImg from '@/assets/img/advocacy-for-children/city_certify@2x.png';
import Evaluate10 from '@/assets/img/advocacy-for-children/city_evaluate10@2x.png';
import Evaluate1 from '@/assets/img/advocacy-for-children/city_evaluate1@2x.png';
import Evaluate2 from '@/assets/img/advocacy-for-children/city_evaluate2@2x.png';
import Evaluate3 from '@/assets/img/advocacy-for-children/city_evaluate3@2x.png';
import Evaluate4 from '@/assets/img/advocacy-for-children/city_evaluate4@2x.png';
import Evaluate5 from '@/assets/img/advocacy-for-children/city_evaluate5@2x.png';
import Evaluate6 from '@/assets/img/advocacy-for-children/city_evaluate6@2x.png';
import Evaluate7 from '@/assets/img/advocacy-for-children/city_evaluate7@2x.png';
import Evaluate8 from '@/assets/img/advocacy-for-children/city_evaluate8@2x.png';
import Evaluate9 from '@/assets/img/advocacy-for-children/city_evaluate9@2x.png';
import CityImg from '@/assets/img/advocacy-for-children/city_img@2x.png';
import Arrow from '@/assets/img/icons/icon_white_arrow_24x25@2x.png';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import {
  SectionObj,
  NoList,
  ObjList,
  DlBox,
} from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PublicPrivateTab } from '@/page-blocks/advocacy-for-children/public-private-partnership';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const evaluateData = [
  {
    id: 0,
    img: Evaluate1,
    no: `01`,
    tit: `아동권리 <br /> 전담조직`,
  },
  {
    id: 1,
    img: Evaluate2,
    no: `02`,
    tit: `아동친화적인 <br />법체계`,
  },
  {
    id: 2,
    img: Evaluate3,
    no: `03`,
    tit: `아동의 <br />참여체계`,
  },
  {
    id: 3,
    img: Evaluate4,
    no: `04`,
    tit: `아동권리 <br />독립적 대변인`,
  },
  {
    id: 4,
    img: Evaluate5,
    no: `05`,
    tit: `아동권리 <br />교육 및 홍보`,
  },
  {
    id: 5,
    img: Evaluate6,
    no: `06`,
    tit: `아동예산 분석 <br />및 확보`,
  },
  {
    id: 6,
    img: Evaluate7,
    no: `07`,
    tit: `정기적인 <br />아동권리현황 조사`,
  },
  {
    id: 7,
    img: Evaluate8,
    no: `08`,
    tit: `아동친화도시 <br />조성전략수립`,
  },
  {
    id: 8,
    img: Evaluate9,
    no: `09`,
    tit: `아동영향평가`,
  },
  {
    id: 9,
    img: Evaluate10,
    no: `10`,
    tit: `아동의 안전을 <br />위한 조치<small>(유니세프한국위원회 지정요소)</small>`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동친화사회팀`,
    tel: `02-724-8561~3`,
    email: `cfc@unicef.or.kr`,
  },
];

const HowArticle = styled.article`
  padding-top: 96px;
  .dl-wrap {
    /* margin-bottom: 96px; */

    &:last-child {
      margin-bottom: 0;
    }

    & > dd {
      margin-top: 64px;
    }
  }

  figure {
    text-align: center;
    ${Image} {
      max-width: 1009px;
      display: inline-block;
    }
  }
  ${breakpoint(`tablet`)} {
    padding-top: 72px;
    .dl-wrap {
      margin-bottom: 72px;

      & > dd {
        margin-top: 48px;
      }
    }
  }
`;

const SectionCouncil = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 172px;
  }
  .bn-wrap {
    display: block;
    background: #1cabe2;
    padding: 56px 40px;
    justify-content: space-between;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }
    &::before {
      width: 192px;
      height: 192px;
      border-radius: 0 0 100% 0;
      left: 0;
      top: 0;
      background: #2589c9;
    }
    &::after {
      width: 25px;
      height: 43px;
      background: url(${Arrow}) center no-repeat;
      background-size: cover;
      right: 37px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .col-dec {
      position: relative;
      z-index: 1;

      ${Tit} {
        display: block;
      }
    }
    .col-img {
      position: absolute;
      right: 105px;
      bottom: 0;
      width: 402px;
    }
  }
  ${breakpoint(1160)} {
    .bn-wrap {
      &::before {
        width: 16.55vw;
        height: 16.55vw;
      }

      .col-img {
        right: 9.05vw;
        max-width: 34.66vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 72px;
    }
    .bn-wrap {
      padding: 18px 24px 0;

      &::after {
        width: 10px;
        height: 17px;
        right: 17px;
      }
      .col-img {
        position: relative;
        right: auto;
        margin: 0 -24px;
        max-width: initial;
        width: auto;
      }
    }
  }
`;

const ChildObjList = styled(ObjList)`
  align-items: start !important;
`;

const ChildFriendlyCities: React.FC<PageProps> = ({ location }) => {
  const [news, setNews] = useState<CampaignData[]>([]);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A024'],
      });
      const articles = data as any;
      setNews(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  return (
    <LayoutWithTitle
      // 강제로 breadcrumb 고정
      location={{
        ...location,
        pathname: `/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-cities`,
      }}
      paddingBtt
      title="아동친화사회 만들기"
      description="for every child, cities"
    >
      <PublicPrivateTab currentPath={location.pathname} />
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  아동의 권리가 실현되는 지역사회 만들기,
                  <br className="small-hide" />
                  유니세프아동친화도시
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                유니세프아동친화도시(이하 '아동친화도시')는 유니세프가
                지방자치단체와의 파트너십을 통해 유엔아동권리협약을 이행하기
                위해 노력하는 도시 또는 지역 거버넌스 시스템을 구축하는
                프로그램입니다. 유엔아동권리협약의 비차별원칙(제2조), 아동
                최선의 이익(제3조), 생존과 발달의 권리(제6조), 아동의견
                존중(제12조) 4가지 일반 원칙을 기반으로 아동권리 보장에 필수적인
                10가지 구성요소를 갖춘 지역사회를 아동친화도시로 인증합니다.
                또한 정기적인 영향평가를 통해 지방자치단체의 성과를 파악하고
                아동권리 증진을 위한 지속적인 노력을 기울이도록 촉구합니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="6WCWEXAw6qA" />
          </PageComponent>
        </Container>
      </Section>

      <SectionObj className="by-sub-main-layout">
        <Container>
          <div className="flex-wrap flex">
            <SectionHeader>
              <h2>
                <Tit size="s1">
                  모든 아동이
                  <br />
                  행복한 지역사회 -
                  <br />
                  유니세프아동친화도시
                </Tit>
              </h2>
              <p className="header-dec">
                1996년 이스탄불에서 열린 제2차 유엔 인간정주회의에서 어린이의
                안녕(Well-being)이 건강한 사회와 민주주의, 굿거버넌스의
                지표이며, 어린이가 행복한 도시야말로 모든 사람이 행복할 수 있는
                도시임을 선언했습니다. 이에 유니세프는 유엔아동권리협약(UNCRC)의
                비차별원칙(제2조), 아동 최선의 이익(제3조), 생존과 발달의
                권리(제6조), 아동의견 존중(제12조) 4가지 일반 원칙을 기반으로
                아동의 권리 보장에 필수적인 요소를 갖추고 괄목할 만한 성과를
                보여준 지방자치단체를 유니세프아동친화도시로 인증하고 있습니다.
                또한 4년마다 주기적인 평가와 컨설팅 과정을 통해 지방자치단체가
                아동권리 증진을 위한 지속적인 노력을 기울일 수 있도록 지원하고
                있습니다.
              </p>
              <p className="header-dec">
                한국의 유니세프아동친화도시 이니셔티브는 2013년 11월 서울
                성북구를 첫번째 유니세프아동친화도시로 인증하면서 시작되었고,
                2023년 3월 현재 116개 지방자치단체와 함께 하고 있으며 그 중
                83개가 아동친화도시로 인증을 받았습니다.
              </p>
            </SectionHeader>
            <figure className="col-img">
              <Image pcSrc={CityImg} mobileSrc={CityImg} />
            </figure>
          </div>

          <div className="article-container">
            <article>
              <h3>
                <Tit size="s1">아동친화도시 5대 목표</Tit>
              </h3>
              <p className="header-dec">
                아동친화도시 이니셔티브의 장기적인 목표는{` `}
                <strong>
                  '아동을 위한 지속가능한 변화와 노력으로 아동권리 증진을 위한
                  지역사회 이해관계자의 역량 강화'
                </strong>
                입니다. 즉, 아동친화도시는 유엔아동권리협약에 담긴 아동권리를
                실현하기 위해 아래 5가지 목표를 향해 지속적으로 변화하고
                발전하는 과정을 보여주어야 합니다.
              </p>
              <br />
              <br />
              <NoList>
                <li>
                  모든 아동은 지역사회와 공동체 안에서 그 권리를 존중받고 동등한
                  시민으로서 대우받아야 합니다.
                </li>
                <li>
                  모든 아동은 자신에게 영향을 주는 법률과 공공 정책, 예산,
                  프로그램에 대해 의견을 말하고 그 의견을 존중받아야 합니다.
                </li>
                <li>
                  모든 아동은 양질의 기본적 서비스를 누릴 권리가 있습니다.
                </li>
                <li>
                  모든 아동은 오염되지 않은 환경에서 안전하게 살 권리가
                  있습니다.
                </li>
                <li>
                  모든 아동은 가정생활에 참여하고 여가를 즐기며 놀 권리가
                  있습니다.
                </li>
              </NoList>
            </article>

            <article>
              <h3>
                <Tit size="s1">인증은 어떻게 이루어지나요?</Tit>
              </h3>

              <HowArticle>
                <dl className="dl-wrap">
                  <dt>
                    <Tit size="s2" color="sky">
                      인증심사 기준
                    </Tit>
                  </dt>
                  <dd>
                    <ChildObjList className="flex">
                      {evaluateData.map((row) => (
                        <li key={row.id}>
                          <div className="img-wrap">
                            <Image pcSrc={row.img} mobileSrc={row.img} />
                          </div>
                          <div className="dec-wrap">
                            <strong className="no">{row.no}</strong>
                            <Tit
                              size="s4"
                              dangerouslySetInnerHTML={{ __html: row.tit }}
                            />
                          </div>
                        </li>
                      ))}
                    </ChildObjList>
                  </dd>
                </dl>
                <dl className="dl-wrap">
                  <dt>
                    <Tit size="s2" color="sky">
                      인증절차
                    </Tit>
                  </dt>
                  <dd>
                    <figure>
                      <Image pcSrc={CertifyImg} mobileSrc={CertifyImg} />
                    </figure>
                    <p className="dec">
                      아동친화도시 조성을 기획, 이행, 평가하는 모든 단계가 상호
                      유기적으로 연결되어 있습니다. 한차례 인증에 그치지 않고
                      지방자치단체의 지속적인 변화와 노력을 통해 지역사회
                      이해관계자의 역량을 강화하고 궁극적으로는 '아동권리가
                      온전히 실현되는 지역사회'를 조성합니다.
                    </p>

                    <DlBox>
                      <dt>
                        <Tit size="s3" color="sky">
                          아동친화도시 인증제 기준
                        </Tit>
                      </dt>
                      <dd>
                        <ul className="cnt-list">
                          <li>
                            1. 여러 성과 영역에 걸쳐{` `}
                            <strong>포괄적인 아동권리적 접근</strong>이 이루어질
                            수 있도록 아동을 위해 이루어 낸 변화를 보여주어야
                            합니다.
                          </li>
                          <li>
                            2. 아동 의회나 청소년 의회와 같은 제도적 참여기구를
                            통해 아동친화도시를 추진하는 모든 단계에서
                            지방자치단체의 의사결정에{` `}
                            <strong>아동이 적극적으로 참여</strong>
                            합니다.
                          </li>
                          <li>
                            3. 지방자치단체가 아동친화도시 사업을 비롯한 각종
                            정책과 제도적 조치를 통해{` `}
                            <strong>
                              아동과 청소년에 대한 차별을 철폐하기 위해 어떤
                              노력을
                            </strong>
                            {` `}
                            하고 있는지 보여주어야 합니다.
                          </li>
                        </ul>
                      </dd>
                    </DlBox>
                  </dd>
                </dl>
              </HowArticle>
            </article>
          </div>
        </Container>
      </SectionObj>

      <SectionCouncil className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                유니세프아동친화도시 추진
                <br /> 지방정부협의회
              </Tit>
            </h2>
            <p className="header-dec">
              유니세프아동친화도시 추진 지방정부협의회는 유니세프한국위원회와
              국내 유니세프아동친화도시 추진을 위해 협력하는 협의체입니다.
              아동친화도시로 인증 받았거나 인증을 추진하는 지방자치단체가
              지속적으로 늘어나면서 지방자치단체 간 소통을 위해, 2015년 27개
              지방자치단체가 모여 「유니세프 아동친화도시 추진
              지방정부협의회」를 결성했습니다. 유니세프한국위원회와
              지방정부협의회는 아동권리 컨퍼런스와 세미나, 간담회 등을 공동
              개최해 아동권리 인식을 제고하고, 지방자치단체 간 아동친화도시 모범
              사례를 공유하고 배우는 소통의 장을 마련합니다.
            </p>
          </SectionHeader>

          <LinkSafe
            to="http://childfriendlycities.kr/"
            target="_blank"
            className="bn-wrap"
          >
            <div className="col-dec">
              <Tit size="s4-1" color="yellow">
                마이크로 사이트 바로가기
              </Tit>
              <Tit size="s1" color="white">
                유니세프아동친화도시{` `}
              </Tit>
            </div>
            <figure className="col-img">
              <Image pcSrc={Bn} mobileSrc={Bn} />
            </figure>
          </LinkSafe>
        </Container>
      </SectionCouncil>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A024" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {news.length > 0 && (
            <RelativeBoardCard
              newslist={news}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};
export default ChildFriendlyCities;
